// @flow
import { useState, useEffect, useCallback, useRef } from 'react'
import { useLiteracyEventGrade } from './useLiteracyEvent'
import { usePrevious } from '../../../utility/hooks'
import { LITERACY_EVENT } from '@mission.io/mission-toolkit/constants'
import { ONE_SECOND } from '../../../constants'

const OPTIMISTIC_UPDATE_FAILED_TIMER = 15 * ONE_SECOND // if this amount of time passes after an optimistic status update, remove the optimistic changes and fallback to a status which was not set optimistically

export const DISPLAY_STATUS = {
	INACTIVE: 'INACTIVE',
	INTRO: 'INTRO',
	ACTIVE: 'ACTIVE',
	EXITING: 'EXITING',
	SUBMITTING: 'SUBMITTING',
	DISPLAY_GRADE: 'DISPLAY_GRADE',
	SHOW_EXPLANATION: 'SHOW_EXPLANATION',
	COMPLETE: 'COMPLETE',
}

const ORDER = [
	DISPLAY_STATUS.INACTIVE,
	DISPLAY_STATUS.INTRO,
	DISPLAY_STATUS.ACTIVE,
	DISPLAY_STATUS.EXITING, // If tasks are stacked, we return to the ACTIVE status after a speaking task completes its EXIT status.
	DISPLAY_STATUS.SUBMITTING, // This display status is NOT used for speaking tasks
	DISPLAY_STATUS.DISPLAY_GRADE, // This display status is NOT used for speaking tasks
	DISPLAY_STATUS.SHOW_EXPLANATION, // This display status is NOT used for speaking tasks, and only sometimes used by other tasks.
]

const NEXT_STEP_AFTER_EXIT = ORDER[ORDER.findIndex(status => status === DISPLAY_STATUS.EXITING) + 1]

type LiteracyEventDisplayStatus = $Keys<typeof DISPLAY_STATUS>

/**
 * A hook to manage the display status of a literacy event.
 * @param {string | void} id - The id of the literacy event
 * @returns {[LiteracyEventDisplayStatus, (newStatus: LiteracyEventDisplayStatus) => void]} - A tuple containing the current status and a function to update the status
 */
export function useLiteracyEventDisplayStatus(
	id: string | void
): [
	LiteracyEventDisplayStatus,
	(
		LiteracyEventDisplayStatus,
		opts?: { for?: { id: string, type: string }, isOptimisticUpdate?: boolean }
	) => void
] {
	const [
		status,
		_setStatus, // use setStatusWithOptimisticRollback, do not use this function directly
	] = useState<LiteracyEventDisplayStatus>(DISPLAY_STATUS.INACTIVE)
	const optimisticFallbackRef = useRef<{
		timerId?: ?TimeoutID,
		status: LiteracyEventDisplayStatus,
	}>({ status }) // if an optimistic update is not overridden with a non-optimistic update in this, this is the status which will be jumped back to
	const exitingFor = useRef()

	const shouldDisplayGrade = useRef()
	// Assuming if a student grade is undefined or null it has not been calculated yet
	const studentGrade = useLiteracyEventGrade()
	const prevGrade = usePrevious(studentGrade)

	useEffect(() => () => clearTimeout(optimisticFallbackRef.current.timerId), [])

	/**
	 * setStatusWithOptimisticRollback - a wrapper around _setStatus which handles rolling back optimistic updates if needed
	 *
	 * @param {LiteracyEventDisplayStatus} newStatus - the new status
	 * @param {boolean} isOptimisticUpdate - true if this state relies on a change on the fullState to progress (ie. a websocket message was sent and needs to be handled to progress), false otherwise (ie. all state changes needed to progress rely solely on the state the client controls).
	 *                                       If true, this status update may be reverted if a non-optimistic status update does not occur within `OPTIMISTIC_UPDATE_FAILED_TIMER` milliseconds.
	 *                                       This argument was added to prevent clients from soft locking if the server did not receive the message (ie. the websocket dropped the needed message due to a network error or some other fault).
	 */
	const setStatusWithOptimisticRollback = useCallback(
		(newStatus: LiteracyEventDisplayStatus, isOptimisticUpdate: boolean) => {
			_setStatus(newStatus)

			// if the status update is done optimistically (ie. relies on a message being handled on the server to update state), roll back to a previously known good status after a certain amount of time if the status is not updated to a non-optimistic updated status
			clearTimeout(optimisticFallbackRef.current?.timerId)
			if (isOptimisticUpdate) {
				optimisticFallbackRef.current.timerId = setTimeout(() => {
					_setStatus(optimisticFallbackRef.current.status)
				}, OPTIMISTIC_UPDATE_FAILED_TIMER)
				return
			}

			optimisticFallbackRef.current = {
				status: newStatus,
			}
		},
		[]
	)

	useEffect(() => {
		if (studentGrade != null && shouldDisplayGrade.current) {
			setStatusWithOptimisticRollback(DISPLAY_STATUS.DISPLAY_GRADE, false)
			shouldDisplayGrade.current = false
		}
	}, [studentGrade, setStatusWithOptimisticRollback])

	useEffect(() => {
		if (!id) {
			setStatusWithOptimisticRollback(DISPLAY_STATUS.INACTIVE, false)
			shouldDisplayGrade.current = false
			exitingFor.current = null
		}
		if (id) {
			setStatusWithOptimisticRollback(DISPLAY_STATUS.INTRO, false)
		}
	}, [id, setStatusWithOptimisticRollback])

	// Move on after student dismisses the current task
	useEffect(() => {
		if (id && prevGrade != null && studentGrade == null) {
			setStatusWithOptimisticRollback(DISPLAY_STATUS.ACTIVE, false)
		}
	}, [studentGrade, id, prevGrade, setStatusWithOptimisticRollback])

	const setStatus = useCallback(
		(
			newStatus: LiteracyEventDisplayStatus,
			options?: { for?: { id: string, type: string }, isOptimisticUpdate?: boolean }
		) => {
			// Special logic for handling the ending of a speaking task: when a speaking task is finished with the EXIT display, set the status to active
			// so the next literacy event (if there is any) can animate in.
			if (
				newStatus === NEXT_STEP_AFTER_EXIT &&
				exitingFor.current &&
				exitingFor.current.type === LITERACY_EVENT.TASK.TYPE.SPEAKING
			) {
				setStatusWithOptimisticRollback(DISPLAY_STATUS.ACTIVE, options?.isOptimisticUpdate ?? false)
				exitingFor.current = null
				return
			}

			if (ORDER.indexOf(newStatus) !== ORDER.indexOf(status) + 1) {
				throw new Error(`Cannot set status to ${newStatus} from ${status}`)
			}
			// Handle race condition where we are waiting for the grade to be calculated
			if (newStatus === DISPLAY_STATUS.DISPLAY_GRADE && studentGrade == null) {
				shouldDisplayGrade.current = true
				return
			}

			if (options?.for && newStatus === DISPLAY_STATUS.EXITING) {
				exitingFor.current = options.for
			}
			setStatusWithOptimisticRollback(newStatus, options?.isOptimisticUpdate ?? false)
		},
		[status, studentGrade, setStatusWithOptimisticRollback]
	)

	return [status, setStatus]
}
