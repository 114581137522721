import type { Store } from '../store'
import type { PointEvent } from '../types'

import { ONE_SECOND } from '../constants'
import { getStation } from '../store/stores/general'
import { addPointEvent } from '../store/stores/points'
import { addJoiningInfoToWebsocketUrl } from '../utility/urls'
import { WEBSOCKET_CLOSE_CODES, onWebsocketClose } from './closeHandler'
import { WEBSOCKET_STATUS, getWebsocketStatus, setWebsocketStatus } from './websocketStatus'
import { handleMessageInOrder } from './messageHandler'
import uuid from 'uuid/v4'
import config from '../config'
import { clearAllOutstandingPings, registerPingForNetworkMonitoring } from './networkHealth'

let currentStore = null
/**
 * connectStoreToWebsocket - connect the redux store to the websocket. This is used to avoid a circular dependency
 *
 * @param {Store} store - the store to use in the websocket
 */
export function connectStoreToWebsocket(store: Store) {
	currentStore = store
}

let missionWebsocket: ?WebSocket = null
let currentConnectionId: ?string = null
let previousSetup: ?{ missionWsUrl: string, missionCode: string } = null
let connectedTimesBefore = 0 // the number of times the client was able to connect to the mission
let lastCloseInformation: null | { time: Date, code: number | null } = null // the time that the previous websocket closed as well as the close code

type MissionMessage = {
	type: string,
	payload?: any,
	meta?: {
		POINT_EVENT_ID?: string,
	},
}
let messageQueue: Array<string> = [] // json stringified MissionMessage

export const MAX_RETRY_ATTEMPT = 10
export const MAX_RETRY_DELAY = 20 * ONE_SECOND

/**
 * connectWebsocketToMission - connect a websocket to to the mission code with the missionCode at the given missionWsUrl
 *
 * @param {string} missionWsUrl - the websocket server to connect to
 * @param {string} missionCode - the code of the mission to connect to
 */
export function connectWebsocketToMission(missionWsUrl: string, missionCode: string) {
	const currentConnectionStatus = getWebsocketStatus()

	if (
		missionCode === currentConnectionStatus.missionCode &&
		!(
			currentConnectionStatus.status === WEBSOCKET_STATUS.DISCONNECTED ||
			currentConnectionStatus.status === WEBSOCKET_STATUS.GAVE_UP
		)
	) {
		return // already connected or trying to connect to the wanted mission
	}

	connectedTimesBefore = 0 // connecting to a new mission

	const newConnectionId = uuid()
	currentConnectionId = newConnectionId
	if (missionWebsocket) {
		missionWebsocket.close()
	}
	previousSetup = {
		missionWsUrl,
		missionCode,
	}

	retryConnectingToMission(
		addJoiningInfoToWebsocketUrl(missionWsUrl, missionCode),
		missionCode,
		newConnectionId,
		0
	)
}

/**
 * retryConnectingToMission - entry point into _retryConnectingToMission, handles waiting between attempts to connect to websocket server
 *
 * @param {string} missionWsUrl - the url of the websocket server to connect to
 * @param {string} missionCode - the code of the mission to connect to
 * @param {string} connectionId - the id of the connection being established
 * @param {number} retryAttempt - the number of attempts connecting to the given mission at the given url
 */
function retryConnectingToMission(
	missionWsUrl: string,
	missionCode: string,
	connectionId: string,
	retryAttempt: number,
	lastCloseCode?: number
) {
	const delay = connectionBackoff(retryAttempt)
	if (delay <= 0) {
		_retryConnectingToMission(missionWsUrl, missionCode, connectionId, retryAttempt)
		return
	}
	setWebsocketStatus({
		status: WEBSOCKET_STATUS.RETRY_AT,
		attempt: retryAttempt,
		missionCode,
		retryAt: new Date(Date.now() + delay),
		connectedTimesBefore,
		lastCloseCode,
	})

	setTimeout(
		() => _retryConnectingToMission(missionWsUrl, missionCode, connectionId, retryAttempt),
		delay
	)
}

/**
 * _retryConnectingToMission - create a websocket connected to the mission with the given missionCode located at the given missionWsUrl
 *
 * @param {string} missionWsUrl - the url of the websocket server to connect to
 * @param {string} missionCode - the code of the mission to connect to
 * @param {string} connectionId - the id of the connection being established
 * @param {number} retryAttempt - the number of attempts connecting to the given mission at the given url
 *
 */
export function _retryConnectingToMission(
	missionWsUrl: string,
	missionCode: string,
	connectionId: string,
	retryAttempt: number,
	lastCloseCode?: number
) {
	if (
		// this connection has been canceled
		connectionId !== currentConnectionId
	) {
		return
	}
	if (retryAttempt > MAX_RETRY_ATTEMPT) {
		console.error(
			`websocket gave up connecting to ${missionWsUrl} because retry attempt exceeded limit`
		)
		setWebsocketStatus({
			status: WEBSOCKET_STATUS.GAVE_UP,
			afterAttempts: retryAttempt,
			missionCode,
			connectedTimesBefore,
			lastCloseCode,
		})
		return
	}

	setWebsocketStatus({
		status: WEBSOCKET_STATUS.CONNECTING,
		attempt: retryAttempt,
		missionCode,
		connectedTimesBefore,
		lastCloseCode,
	})

	let newMissionWebsocket = new WebSocket(missionWsUrl)

	newMissionWebsocket.addEventListener('open', () => {
		if (connectionId !== currentConnectionId) {
			// this connection has been canceled
			newMissionWebsocket.close()
			return
		}

		setWebsocketStatus({
			status: WEBSOCKET_STATUS.CONNECTED,
			missionCode,
			connectedTimesBefore,
		})

		clearAllOutstandingPings() // any outstanding pings is not for this websocket

		connectedTimesBefore += 1

		messageQueue.forEach(message => {
			newMissionWebsocket.send(message)
		})
		messageQueue = []

		refreshPingTimer()

		const previousWebsocketCloseInformation = lastCloseInformation // assigned to get around flow error
		if (previousWebsocketCloseInformation) {
			let metrics: Array<Metric> = [
				{
					metric: 'clientReconnectedAfter',
					value: Date.now() - previousWebsocketCloseInformation.time,
				},
			]

			if (previousWebsocketCloseInformation.code === WEBSOCKET_CLOSE_CODES.PING_FAILURE) {
				metrics.push({
					metric: 'connectionDroppedDueToPingFailure',
					value: 1,
				})
			}

			sendMetrics(metrics)
		}
	})

	// $FlowFixMe[incompatible-call] close message is not typed correctly, this is correct based off the MDN docs https://developer.mozilla.org/en-US/docs/Web/API/WebSocket/close_event
	newMissionWebsocket.addEventListener('close', (closeMessage: CloseEvent) => {
		if (connectionId !== currentConnectionId) {
			// this connection has been canceled
			return
		}

		missionWebsocket = null
		lastCloseInformation = { time: new Date(), code: closeMessage.code ?? null }
		setWebsocketStatus({
			status: WEBSOCKET_STATUS.DISCONNECTED,
			missionCode,
			connectedTimesBefore,
			lastCloseCode: closeMessage.code,
		})

		clearAllOutstandingPings() // this websocket is closed, so no outstanding pings will be responded to

		if (!currentStore) {
			// this should not ever happen
			return
		}

		const shouldRetry = onWebsocketClose(closeMessage, currentStore)
		if (!shouldRetry.retry) {
			previousSetup = null
			return
		}
		retryConnectingToMission(
			missionWsUrl,
			missionCode,
			connectionId,
			shouldRetry.resetAttempts ? 0 : retryAttempt + 1,
			closeMessage.code
		)
	})

	newMissionWebsocket.addEventListener('message', async (message: MessageEvent) => {
		if (connectionId !== currentConnectionId) {
			// this connection has been canceled
			return
		}

		refreshPingTimer()

		if (!currentStore) {
			// this should not ever happen
			return
		}
		handleMessageInOrder(message.data, currentStore)
	})

	// $FlowFixMe[incompatible-call] the error event listener is not typed correctly
	newMissionWebsocket.addEventListener('error', (error: ErrorEvent) => {
		if (connectionId !== currentConnectionId) {
			// this connection has been canceled
			return
		}
		console.error(`websocket closed due to an error`, error)

		newMissionWebsocket.close()
	})

	missionWebsocket = newMissionWebsocket
}

/**
 * closeWebsocketAndKeepClosed - close the current websocket connected to the mission
 */
export function closeWebsocketAndKeepClosed() {
	currentConnectionId = null

	if (missionWebsocket) {
		missionWebsocket.close()
		missionWebsocket = null
		lastCloseInformation = null
		setWebsocketStatus({
			status: WEBSOCKET_STATUS.DISCONNECTED,
			connectedTimesBefore,
		})
	}
}

/**
 * closeWebsocketDueToNetworkError - closes the current websocket with the given code. Note: this
 * may try to reconnect depending on the code.
 *
 * @param {number} code - the code to close the websocket with
 */
export function closeWebsocketDueToNetworkError(code: number) {
	missionWebsocket?.close(code)
}

/**
 * reconnect - attempt to reconnect to the mission
 */
export function reconnect() {
	if (!previousSetup) {
		return
	}
	connectWebsocketToMission(previousSetup.missionWsUrl, previousSetup.missionCode)
}

/**
 * sendMessage - send a websocket message to the mission server
 *
 * @param {string} type - the type of the message being sent
 * @param {any} payload - the data send as part of the message
 * @param {PointEvent} pointEvent? - information about locations of where point pings should spawn if points are awarded due to the message sent the mission server
 */
export function sendMessage(type: string, payload?: any, pointEvent?: PointEvent) {
	let message: MissionMessage = {
		type,
	}
	if (payload) {
		message.payload = payload
	}

	if (pointEvent) {
		if (!currentStore) {
			// this should not be possible
		} else {
			const pointEventId = uuid()
			currentStore.dispatch({
				// spread to help with flow
				...addPointEvent({
					id: pointEventId,
					location: pointEvent.location,
					station: getStation(currentStore.getState()),
				}),
			})
			message.meta = { POINT_EVENT_ID: pointEventId }
		}
	}

	let stringMessage = JSON.stringify(message)
	if (messageQueue.length || !missionWebsocket || missionWebsocket.readyState !== WebSocket.OPEN) {
		messageQueue.push(stringMessage)
		return
	}

	missionWebsocket.send(stringMessage)
}

/**
 * connectionBackoff - get the duration to wait before the next attempt
 *
 * @param {number} attempt - the current attempt number
 *
 * @return {number} - the number of milliseconds to wait before trying to connect again
 */
function connectionBackoff(attempt: number): number {
	if (attempt === 0) {
		return 0
	}
	return Math.min(
		// arbitrarily chosen
		ONE_SECOND ** (1 + attempt / 16) +
			// jitter to make sure not all websocket try to reconnect at the same time
			(ONE_SECOND / 8) * Math.random(),
		MAX_RETRY_DELAY
	)
}

// ========================== network health monitoring =======================

// ========= PING ======

let pingAfterInactivityTimerId: TimeoutID | null = null // id of a timer used to ping the server after a period of network inactivity
export const PING_AFTER_INACTIVITY = 10 * ONE_SECOND // check connection status after 10 seconds of inactivity
export const PING_AFTER_INACTIVITY_JITTER = 5 * ONE_SECOND // maximum jitter allowed for inactivity ping
export type PingData = {| clientTime: number, pingId: string |}

function sendPing() {
	const pingPayload: PingData = { clientTime: Date.now(), pingId: uuid() }

	if (!missionWebsocket || missionWebsocket.readyState !== WebSocket.OPEN) {
		return
	}

	missionWebsocket.send(JSON.stringify({ type: 'PING', payload: pingPayload }))
	registerPingForNetworkMonitoring(pingPayload)
	refreshPingTimer()
}

/**
 * refreshPingTimer - resets the ping after inactivity timer. When the timer completes, it will send a PING message (not a control frame 'ping')
 * to the server. This is to make sure the client is connected. Safari and Firefox do this with control frames automatically, but chrome does not.
 * On chrome, the client will only realize there is a network error if a packet fails to get to the server. As such, this function causes packets to be
 * sent after a certain amount of time of network inactivity.
 */
function refreshPingTimer() {
	if (pingAfterInactivityTimerId) {
		clearTimeout(pingAfterInactivityTimerId)
	}
	if (!config.featureFlags.useManualWebsocketPings) {
		return
	}

	pingAfterInactivityTimerId = setTimeout(
		sendPing,
		PING_AFTER_INACTIVITY + Math.random() * PING_AFTER_INACTIVITY_JITTER
	)
}

type Metric =
	| {
			metric: 'clientCrashes',
			value: number,
	  }
	| {
			metric: 'connectionDroppedDueToPingFailure',
			value: number,
	  }
	| {
			metric: 'clientReconnectedAfter',
			value: number, // time between last close and next websocket open
	  }

/**
 * sendMetrics - returns an action which, when dispatched, will send the metrics to mission-server
 *
 * @param {Array<Metric>} metrics - the metrics to send to mission-server
 *
 * @return {SendWebSocketMessageAction} the action to dispatch
 */
export function sendMetrics(metrics: Array<Metric>) {
	sendMessage('NOTE_MISSION_METRIC', { metrics })
}

export const TEST_ONLY = {
	sendPing,
}
